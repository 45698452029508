import React, {useEffect, useState} from "react";
import {IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from "@ionic/react";
import {Redirect, Route} from "react-router-dom";
import {RouteComponentProps, useLocation} from "react-router";
import Renders from "./Renders";
import Community from "./Community";
import Profile from "./Profile";
import {
    person,
    personOutline,
    brushOutline,
    brush,
    telescope,
    telescopeOutline
} from "ionicons/icons";
import "@ionic/react/css/core.css";
import {useAuth} from "../../shared/auth/useAuth";
import {doc, onSnapshot, updateDoc} from "firebase/firestore";
import {firestore} from "../../firebaseConfiguration";
import {ChoraUserType, DeviceToken} from "../../shared/types/user/chora-user.type";
import {Toast} from "@capacitor/toast";
import useNotificationsHelper from "../../shared/hooks/notificationsHelper.hook";
import {FirebaseMessaging, Notification} from "@capacitor-firebase/messaging";
import {Device} from "@capacitor/device";

function Home({match}: RouteComponentProps) {
    const {currentUser, authenticated} = useAuth();
    const location = useLocation();
    const [userData, setUserData] = useState<ChoraUserType | undefined>(undefined);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const {checkPermissions, requestPermissions, getToken} = useNotificationsHelper();
    const [notificationsEnabled, setNotificationsEnabled] = useState<boolean>(false);

    useEffect(() => {
        // TODO: Check whether push notifications is enabled
        configurePushNotifications();
    }, [])

    async function configurePushNotifications() {
        const permissionsStatus = await checkPermissions();
        console.log('Permission status: ', permissionsStatus);
        if (permissionsStatus === 'granted') {
            setNotificationsEnabled(true);
            await getToken().then(token => {
                updateUserProfileWithToken(token);
            })
        } else {
            await Toast.show({
                text: 'Please enable push notifications to receive updates on your renders.'
            });
            setTimeout(async () => {
                await requestPermissions().then(async permission => {
                    console.log('Permission request: ', permission)
                    if (permission === 'granted') {
                        setNotificationsEnabled(true);
                        await getToken().then(token => {
                            updateUserProfileWithToken(token);
                        })
                    }
                })
            }, 2000)
        }
    }

    async function updateUserProfileWithToken(fcmToken: string) {
        const deviceInfo = await Device.getInfo();
        console.log('Device info: ', deviceInfo);
        console.log('User UID: ', userData);

        console.log('User FCM Tokens', userData?.fcmTokens);
        // Check if device exists in fcmTokens
        const exists = Boolean(userData?.fcmTokens && userData.fcmTokens.length > 0 && userData?.fcmTokens?.findIndex(token => token.token === fcmToken) !== -1);
        console.log('User token exists: ', exists);
        if (!exists && fcmToken && deviceInfo) {
            const deviceTokenDetails: DeviceToken = {
                name: deviceInfo.name || 'Un-named Device',
                platform: deviceInfo.platform,
                token: fcmToken
            }
            const updatedProfile = {
                deviceTokens: userData?.fcmTokens && userData?.fcmTokens.length > 0 ? [...userData.fcmTokens, deviceTokenDetails] : [deviceTokenDetails]
            }
            await updateDoc(doc(firestore, 'chora-users', `${currentUser?.uid}`), {
                fcmTokens: updatedProfile.deviceTokens
            })
        }

    }

    useEffect(() => {
        if (notificationsEnabled) {
            FirebaseMessaging.addListener('notificationReceived', event => {
                console.log('Notification received: ', event);

                if (event && event?.notification) {
                    setNotifications([...notifications, event.notification]);
                }
            });
        }
    }, [notificationsEnabled])

    useEffect(() => {
        if (!currentUser?.uid || !authenticated) return;

        const unsubscribeUserData = onSnapshot(
            doc(firestore, 'chora-users', `${currentUser?.uid}`),
            (doc) => {
                const data = doc.data() as ChoraUserType;
                    setUserData(data || currentUser);
            }
        );

        return () => {
            unsubscribeUserData();
        };
    }, [authenticated, currentUser?.uid]);

    function isActiveRoute(routeName: string, altRouteName?: string) {
        return location.pathname === routeName || location.pathname === altRouteName;
    }

    const showToast = async (msg: string) => {
        await Toast.show({
            text: msg
        })
    }

    useEffect(() => {
        console.log('Notifications: ', notifications);
    }, [notifications])

    return (
        <IonTabs>
            <IonRouterOutlet>
                <Route exact path={`${match.url}/renders`}>
                    <Renders/>
                </Route>
                <Route exact path={`${match.url}/community`}>
                    <Community/>
                </Route>
                <Route exact path={`${match.url}/profile`}>
                    <Profile notifications={notifications}/>
                </Route>
                <Route exact path={`${match.url}/`}>
                    <Redirect to={`${match.url}/renders`}/>
                </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab="renders" href={`${match.url}/renders`}>
                    <IonIcon aria-hidden="true"
                             icon={isActiveRoute(`${match.url}/renders`, match.url) ? brush : brushOutline}/>
                    <IonLabel>Renders</IonLabel>
                </IonTabButton>
                <IonTabButton tab="community" href={`${match.url}/community`}>
                    <IonIcon aria-hidden="true"
                             icon={isActiveRoute(`${match.url}/community`) ? telescope : telescopeOutline}/>
                    <IonLabel>Community</IonLabel>
                </IonTabButton>
                <IonTabButton tab="profile" href={`${match.url}/profile`}>
                    <IonIcon aria-hidden="true"
                             icon={isActiveRoute(`${match.url}/profile`) ? person : personOutline}/>
                    <IonLabel>Profile</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
}

export default Home;
