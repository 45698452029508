import {auth, googleAuthProvider} from "../../firebaseConfiguration";
import {
    signInWithPopup,
    signInWithEmailAndPassword,
    signInWithRedirect,
    GoogleAuthProvider,
    signInWithCredential
} from "firebase/auth";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";

export const signInWithGoogle = async () => {
    console.log('Signing in with Google...', auth);
    try {
        // TODO: Might have to replace with sign in with credential if it doesn't work
        // return  await signInWithRedirect(auth, googleAuthProvider);

        // 1. Create credentials on the native layer
        const result = await FirebaseAuthentication.signInWithGoogle();
        // 2. Sign in on the web layer using the id token
        const credential = GoogleAuthProvider.credential(result.credential?.idToken);
        return await signInWithCredential(auth, credential);
    } catch (error) {
        console.error('Error signing in with Google:', error);
        return new Promise((resolve, reject) => reject(error));
    }
};

export const signInWithEmail = async (email: string, password: string) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.error('Error signing in with email and password:', error);
        return new Promise((resolve, reject) => reject(error));
    }
};

export const signOut = async () => {
    try {
        await auth.signOut();
    } catch (error) {
        console.error('Error signing out:', error);
    }
};
