import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonPage,
    IonThumbnail,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {handleImageOnError} from "../../components/ImageWithFallback";
import {Toast} from "@capacitor/toast";
import {CommunityRenderType} from "../../shared/types/render/community-render";
import {useAuth} from "../../shared/auth/useAuth";
import useImageHelpers from "../../shared/hooks/imageHelpers.hook";

interface CommunityShareProps {
    renders: string[]
}

function CommunityShare(props: CommunityShareProps) {
    const {renders} = props;
    const [selectedRenders, setSelectedRenders] = React.useState<string[]>([]);

    function shareToCommunity() {
        if (selectedRenders.length === 0) {
            Toast.show({
                text: 'Please select at least one render to share',
                duration: 'short',
            });
        } else {
            Toast.show({
                text: 'Sharing to community...',
                duration: 'short',
            });
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Select Images</IonTitle>

                    <IonButton onClick={shareToCommunity} slot="end" color="success">Share</IonButton>

                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonList>
                    {
                        renders?.length > 0 ? renders.map((render, index) => {
                            return <IonItem>
                                <IonThumbnail slot="start">
                                    <LazyLoadImage id={`image-to-share-${index}`} crossOrigin={''} src={render}
                                                   onError={(event) => handleImageOnError(event)}/>
                                </IonThumbnail>
                                <IonCheckbox onClick={(event) => {
                                    if (selectedRenders.includes(render)) {
                                        setSelectedRenders(selectedRenders.filter((render) => render !== render));
                                    } else {
                                        setSelectedRenders([...selectedRenders, render]);
                                    }
                                }}>{index}</IonCheckbox>
                            </IonItem>
                        }) : <p>No renders to share</p>
                    }
                </IonList>
            </IonContent>
        </IonPage>
    );
}

export default CommunityShare;
