import React from 'react';
import PlaceHolder from "../../shared/assets/img_placeholder.png";

interface ImageWithFallbackProps extends React.ImgHTMLAttributes<HTMLImageElement>{
    fallbackSrc?: string;
}

function ImageWithFallback(props: ImageWithFallbackProps){
    const { src, fallbackSrc, onLoad  } = props;

    return <img src={src} onLoad={onLoad} onError={(event) => handleImageOnError(event, fallbackSrc)} alt="Your description here" />;
}

export const handleImageOnError = (event: React.SyntheticEvent<HTMLImageElement, Event>, fallbackSrc?: string) => {
    event.currentTarget.src = fallbackSrc || PlaceHolder;
};


export default ImageWithFallback;
