import {
    AnimationDirection,
    CreateAnimation, IonActionSheet, IonBackButton, IonButton, IonButtons, IonContent, IonHeader,
    IonIcon, IonPage, IonTitle, IonToolbar, useIonRouter,
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import {
    arrowBack, chevronBackOutline, colorWand,
    compassOutline, copy, copyOutline,
    imagesOutline, shareSocialOutline,
} from "ionicons/icons";
import ImageWithFallback from "../../components/ImageWithFallback";
import {useWindowHelpers} from "../../shared/hooks/windowHelpers.hook";
import "./render-details.scss";
import ReactMarkdown from 'react-markdown';
import useImageHelpers from "../../shared/hooks/imageHelpers.hook";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Toast} from "@capacitor/toast";
import {PhotoViewer} from '@awesome-cordova-plugins/photo-viewer';
import {functions} from "../../firebaseConfiguration";
import {httpsCallable} from 'firebase/functions';
import {useAuth} from "../../shared/auth/useAuth";
import {ActiveRenderViewProps} from "../../shared/types/render/render-details.type";
import {BasicRenderType} from "../../shared/types/render/render-requests.type";
import SunSetLoading from "../../shared/assets/sunset_loading.gif";

interface IRenderDetailsProps {
    renderDetails: ActiveRenderViewProps<BasicRenderType>;
    onCloseProductModal: (closed: boolean) => void,
    renderViewLoaded: (loaded: boolean) => void,
    isCommunity?: boolean
}

function RenderDetails(props: IRenderDetailsProps) {
    const {renderDetails, onCloseProductModal, renderViewLoaded, isCommunity} = props;
    const [imageLoaded, setImageLoaded] = useState(false);
    const [playReverseAnimation, setPlayReverseAnimation] = useState(false);
    const [stopPlaying, setStopPlaying] = useState(false);
    const [playTransitionAnimation, setPlayTransitionAnimation] = useState(false);
    const [animationDirection, setAnimationDirection] = useState<AnimationDirection>('normal');
    const [ease, setEase] = useState('ease');
    const [animationDuration, setAnimationDuration] = useState(320);
    const {getScreenDimensions} = useWindowHelpers();
    const renderDescriptionCardRef = useRef<HTMLDivElement | null>(null);
    const {getImageShadow} = useImageHelpers();
    const router = useIonRouter();
    const {currentUser, authenticated} = useAuth();

    function getCoverImage() {
        if (renderDetails.activeRender?.renders && renderDetails.activeRender.renders.length > 0) {
            return renderDetails.activeRender.renders[0];
        }

        return ''
    }

    function coverImageLoaded() {
        setImageLoaded(true);
        setPlayTransitionAnimation(true);
    }

    function shareToCommunity() {
        if (currentUser && currentUser?.uid) {
            const communityRender = {
                ...renderDetails.activeRender,
                renders: renderDetails.activeRender?.raw_renders || []
            }
            delete communityRender?.raw_renders;
            delete communityRender?.latest_run;

            httpsCallable(functions, 'communityShare')({
                communityShare: communityRender
            }).then((result) => {
                Toast.show({
                    text: 'Shared to community',
                })
            }).catch((error) => {
                console.log('Error sharing to community', error);
                Toast.show({
                    text: 'Error sharing to community',
                })
            });

        }

    }

    useEffect(() => {
        if (renderDetails && imageLoaded) {
            renderViewLoaded(true);
        }
    }, [renderDetails, imageLoaded]);

    function goBack() {
        if (playReverseAnimation) {
            setEase('ease');
            setAnimationDirection('reverse');
            toggleAnimationPlayback(true);

            setTimeout(() => {
                onCloseProductModal(true)
            }, animationDuration);
        }
    }

    function completedViewAnimation() {
        toggleAnimationPlayback(false);
        setPlayReverseAnimation(true);
    }

    function toggleAnimationPlayback(play: boolean) {
        if (play) {
            setStopPlaying(false);
            setPlayTransitionAnimation(true);
        } else {
            setStopPlaying(true);
            setPlayTransitionAnimation(false);
        }
    }

    function copyDescription() {
        // Copy render description to clipboard
        navigator.clipboard.writeText(renderDetails.activeRender?.description || '');
        Toast.show({text: 'Description copied to clipboard'})
    }

    async function viewImage(imageUrl: string) {
        const photoViewerOptions = {
            share: true,
            closeButton: true,
            copyToReference: false,
            headers: ''
        };
        imageUrl && PhotoViewer.show(imageUrl, renderDetails.activeRender?.title || 'Render', photoViewerOptions);
    }

    useEffect(() => {
        return () => {
            goBack();
        }
    }, [])

    return (
        <IonPage style={{visibility: imageLoaded ? "visible" : "hidden", ...getScreenDimensions(), zIndex: '2000000'}}
                 id="render-details-view"
                 className="render-details-page">
            <IonHeader>
                <IonToolbar color="light">
                    <IonButtons slot="start">
                        <IonButton onClick={goBack} type="button">
                            <IonIcon slot="icon-only" size="large" icon={chevronBackOutline}/>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Render Details</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent scrollEvents={true}>

                <div>
                    <CreateAnimation
                        play={playTransitionAnimation}
                        stop={stopPlaying}
                        duration={animationDuration}
                        direction={animationDirection}
                        keyframes={[
                            {
                                offset: 0,
                                transform: `translate3d(${renderDetails.activeRenderViewMetadata.x}px, ${renderDetails.activeRenderViewMetadata.y}px, 0)`,
                                height: `${renderDetails.activeRenderViewMetadata.thumbnail.height}px`,
                                width: `${renderDetails.activeRenderViewMetadata.thumbnail.width}px`,
                                borderRadius: `6px`
                            },
                            {
                                offset: 0.25,
                                transform: `translate3d(${renderDetails.activeRenderViewMetadata.x / 2}px, ${renderDetails.activeRenderViewMetadata.y / 2}px, 0)`,
                                height: `${renderDetails.activeRenderViewMetadata.thumbnail.height * 1.05}px`,
                                width: `${renderDetails.activeRenderViewMetadata.thumbnail.width * 1.05}px`,
                                borderRadius: `4px`
                            },
                            {
                                offset: 0.5,
                                transform: `translate3d(${renderDetails.activeRenderViewMetadata.x / 4}px, ${renderDetails.activeRenderViewMetadata.y / 4}px, 0)`,
                                height: `${renderDetails.activeRenderViewMetadata.thumbnail.height * 1.1}px`,
                                width: `${renderDetails.activeRenderViewMetadata.thumbnail.width * 1.1}px`,
                                borderRadius: `2px`
                            },
                            {
                                offset: 1,
                                transform: `translate3d(0, 0, 0)`,
                                height: `100%`,
                                width: `100%`,
                                borderRadius: 0
                            }
                        ]}>
                        <div className="cover-image">
                            <ImageWithFallback fallbackSrc={SunSetLoading} loading="lazy" id="coverImage"
                                               src={getCoverImage()} onLoad={() => {
                                coverImageLoaded()
                            }}/>
                        </div>
                    </CreateAnimation>

                    <CreateAnimation
                        play={playTransitionAnimation}
                        stop={stopPlaying}
                        duration={animationDuration}
                        onFinish={{callback: () => completedViewAnimation()}}
                        direction={animationDirection}
                        easing={'ease'}
                        keyframes={[
                            {
                                offset: 0,
                                transform: `translateY(100%)`
                            },
                            {
                                offset: 1,
                                transform: `translateY(0)`
                            }
                        ]}>
                        <div ref={renderDescriptionCardRef} id="render-details-body" className="render-details">
                            {
                                renderDetails?.activeRender && <div className="render-details-container">
                                    <p className="render-name">{renderDetails.activeRender?.title}</p>
                                    <p className="render-metadata">
                                        <strong>Strictness:</strong> {renderDetails.activeRender?.strictness}</p>
                                    <p className="render-metadata">
                                        <strong>Similarity:</strong> {renderDetails.activeRender?.similarity}</p>

                                    <div className="render-description">
                                        {
                                            renderDetails.activeRender?.description ?
                                                <ReactMarkdown children={renderDetails.activeRender.description}/> :
                                                <p><i>No description provided for this render.</i></p>
                                        }
                                    </div>

                                    <div className="render-actions">
                                        <div className="w-100 d-flex -row -gap-1">
                                            {
                                                !isCommunity &&
                                                <>
                                                    <IonButton id="share-action-sheet" size="small" color="primary"><IonIcon
                                                        icon={shareSocialOutline} slot={"start"}/>Share</IonButton>
                                                    <IonActionSheet
                                                        trigger="share-action-sheet"
                                                        header="Share To"
                                                        buttons={[
                                                            {
                                                                text: 'Chora Community',
                                                                handler: shareToCommunity
                                                            },
                                                            {
                                                                text: 'Cancel',
                                                                role: 'cancel',
                                                                data: {
                                                                    action: 'cancel'
                                                                }
                                                            }
                                                        ]}
                                                    ></IonActionSheet>
                                                </>
                                            }

                                            <IonButton onClick={copyDescription} size="small" type="button"
                                                       color="light"><IonIcon
                                                icon={copyOutline} slot={"start"}/>Copy Description</IonButton>
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                renderDetails.activeRender?.renders && renderDetails.activeRender.renders.length > 0 ?
                                    <div className="render-details-section">
                                        <div className="section-label">
                                            <IonIcon icon={imagesOutline}/>
                                            <p>Rendered Images</p>
                                        </div>
                                        <div className="render-gallery">
                                            <IonButton size="default" expand="block" type="submit" color="light">
                                                <IonIcon icon={colorWand} slot={"start"}/>Generate More Images
                                            </IonButton>
                                            <div className="images mt-2">
                                                {
                                                    renderDetails.activeRender.renders.map((image: string, index: number) => {
                                                        return <div key={index} className="image-container"
                                                                    id={`image-container_${index}`}>
                                                            <LazyLoadImage id={`render-image_${index}`} src={image}
                                                                           onClick={() => viewImage(image)}
                                                                           afterLoad={() => getImageShadow(image, index, `image-container_${index}`, `render-image_${index}`)}
                                                                           crossOrigin={''}/>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> : <div className="render-details-section">
                                        <div className="section-label">
                                            <p>Rendering in progress...</p></div>

                                    </div>
                            }
                        </div>
                    </CreateAnimation>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default RenderDetails;
