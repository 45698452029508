import React, {useEffect, useState} from "react";
import * as Yup from 'yup';
import {signInWithEmail, signInWithGoogle} from "../../../shared/auth/AuthFunctions";
import {PasswordSignInCredentialsModel} from "../../../shared/auth/auth.type";
import {IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter} from "@ionic/react";
import {useAuth} from "../../../shared/auth/useAuth";

function SignIn() {
    const [googleAuthLoader, setGoogleAuthLoader] = useState(false);
    const [signingInWithPasswordLoader, setSigningInWithPasswordLoader] = useState(false);
    const [authErrorMessage, setAuthErrorMessage] = useState('');
    const [peekPassword, setPeekPassword] = useState(false);
    const router = useIonRouter();
    const {authenticated} = useAuth();

    const signInFormInitialValues = {
        email: '',
        password: ''
    }

    const signInFormSchema = Yup.object().shape({
        email: Yup.string()
            .email('Please enter a valid email address.')
            .required('Please enter your email.'),
        password: Yup.string().required('Please enter your password.')
    });

    useEffect(() => {
        if (authenticated) {
            router.push('/home');
        }
    }, [authenticated])

    async function signInWithPassword(values: any) {
        setAuthErrorMessage('');
        const credentials: PasswordSignInCredentialsModel = values;
        if (credentials) {
            setSigningInWithPasswordLoader(true);
            signInWithEmail(credentials.email, credentials.password).then(() => {
                // TODO: Navigate to the home page
                router.push('/home');
                setSigningInWithPasswordLoader(false);
            }).catch(e => {
                setAuthErrorMessage(e.message);
                setSigningInWithPasswordLoader(false);
            });
        }
    }

    async function googleSignIn() {
        setGoogleAuthLoader(true);
        signInWithGoogle().then(() => {
            // TODO: Navigate to the home page
            router.push('/home');
            setGoogleAuthLoader(false);
        }).catch(e => {
            setAuthErrorMessage(e.message);
            setGoogleAuthLoader(false);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Sign In</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonButton expand="block" color="primary" onClick={() => googleSignIn()}>Sign In With Google</IonButton>
            </IonContent>
        </IonPage>
    )
}

export default SignIn;
