import {
    IonButton,
    IonContent,
    IonHeader, IonLoading,
    IonPage,
    IonTitle,
    IonToolbar, useIonRouter
} from '@ionic/react';
import './Renders.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import useFirebaseHelpers from "../../shared/hooks/firebaseHelpers.hook";
import {DocumentData, onSnapshot, QueryDocumentSnapshot} from "firebase/firestore";
import {firestore} from "../../firebaseConfiguration";
import {useAuth} from "../../shared/auth/useAuth";
import {doc, getDocs, collection} from "firebase/firestore";
import {ChoraUserType} from "../../shared/types/user/chora-user.type";
import Masonry from "react-masonry-css";
import {LazyLoadImage, trackWindowScroll} from "react-lazy-load-image-component";
import useImageHelpers from "../../shared/hooks/imageHelpers.hook";
import {RenderRequestType} from "../../shared/types/render/render-requests.type";
import {handleImageOnError} from "../../components/ImageWithFallback";
import {ActiveViewMetadataType} from "../../shared/types/animated-transition/active-view-metadata.type";
import RenderDetails from "../render-details";
import {ActiveRenderViewProps} from "../../shared/types/render/render-details.type";
import SunSetLoading from "../../shared/assets/sunset_loading.gif";

function Renders() {
    const {currentUser, authenticated} = useAuth();
    const router = useIonRouter();
    const {getDownloadableImage} = useFirebaseHelpers();
    const [userData, setUserData] = useState<ChoraUserType | undefined>(undefined);
    const {getImageShadow} = useImageHelpers();
    const [renderRequests, setRenderRequests] = useState<RenderRequestType[] | undefined>();
    const [activeRenderDetailsView, setActiveRenderDetailsView] = useState<ActiveRenderViewProps<RenderRequestType> | undefined>(undefined);
    const scrollContentRef = React.useRef<HTMLIonContentElement | null>(null);
    const [displayRenderDetails, setDisplayRenderDetails] = useState<boolean>(false);
    const [renderDetailsViewLoaded, setRenderDetailsViewLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!currentUser?.uid || !authenticated) return;

        const unsubscribeUserData = onSnapshot(
            doc(firestore, 'chora-users', `${currentUser?.uid}`),
            (doc) => {
                const data = doc.data() as ChoraUserType;
                if (data) {
                    setUserData(data);
                }
            }
        );

        const unsubscribeRenderRequests = onSnapshot(
            collection(firestore, 'chora-users', `${currentUser?.uid}/render-requests`),
            (querySnapshot) => {
                const renderRequestsData = querySnapshot.docs.map((doc) => {
                    return doc.data() as RenderRequestType;
                });

                const retrievedImageUrls = renderRequestsData.map(async (renderRequestData) => {
                    if (renderRequestData?.renders) {
                        const updatedRendersInRequests = renderRequestData.renders.map(async (render) => {
                            return (await getDownloadableImage(`user-data/${currentUser?.uid}/renders/${render}`));
                        })
                        return {
                            ...renderRequestData,
                            raw_renders: renderRequestData?.renders || [],
                            renders: await Promise.all(updatedRendersInRequests)
                        };
                    } else {
                        return renderRequestData;
                    }
                });
                Promise.all(retrievedImageUrls).then((formattedRenderRequests) => {
                    console.log('Formatted render requests: ', formattedRenderRequests);
                    setRenderRequests(formattedRenderRequests);
                });
            }
        )

        return () => {
            unsubscribeUserData();
            unsubscribeRenderRequests();
        };
    }, [authenticated, currentUser?.uid]);

    function newRender() {
        router.push('/new-render')
    }

    function hideActiveRenderView() {
        setDisplayRenderDetails(false);
        setActiveRenderDetailsView(undefined);
        setRenderDetailsViewLoaded(false);
    }

    function getThumbnailCoverImage(renderRequest: RenderRequestType): string {
        return renderRequest?.renders && renderRequest.renders.length > 0 ? renderRequest.renders[0] : '';
    }

    function openRenderDetails(event: any, renderRequest: RenderRequestType) {
        const target = event.target;
        const eventData: ActiveViewMetadataType = {
            x: target.x,
            y: target.y,
            thumbnail: {
                height: target.offsetHeight,
                width: target.offsetWidth,
            }
        };

        console.log('Event data: ', eventData);

        setActiveRenderDetailsView({
            activeRenderViewMetadata: eventData,
            activeRender: renderRequest,
            displayRenderDetails: true
        });
        setDisplayRenderDetails(true);

        scrollContentRef.current?.scrollToTop(200);
    }


    return (
        <IonPage style={{visibility: renderDetailsViewLoaded ? 'hidden' : 'visible'}}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>My Renders</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen ref={scrollContentRef} scrollEvents={true}
                        style={{overflow: activeRenderDetailsView && Object.entries(activeRenderDetailsView.activeRender).length > 0 ? 'hidden' : 'scroll'}}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">My Renders</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {
                    activeRenderDetailsView?.activeRender && Object.entries(activeRenderDetailsView.activeRender).length > 0 ?
                        <IonLoading spinner="dots"
                                    isOpen={!renderDetailsViewLoaded}/> : ''
                }

                <div className="ion-padding">
                    <IonButton expand="block" onClick={newRender}
                               color="secondary">New Render</IonButton>
                    <Masonry
                        breakpointCols={2}
                        className="renders-grid mt-4"
                        columnClassName="renders-column">

                        {
                            renderRequests && renderRequests.map((renderRequest, index) => {
                                return <div key={index}
                                            style={{"--entry-index": index} as any}
                                            className={`render ${renderDetailsViewLoaded ? '-hidden' : ''}`}>
                                    <div id={`render-container-${index}`} onClick={(event) => {
                                        openRenderDetails(event, renderRequest)
                                    }} className="render-thumbnail">
                                        <LazyLoadImage id={`render-image-${index}`}
                                                       crossOrigin={''}
                                                       src={getThumbnailCoverImage(renderRequest)}
                                                       onError={(event) => handleImageOnError(event, SunSetLoading)}
                                                       afterLoad={() => getImageShadow(getThumbnailCoverImage(renderRequest), index, `render-container-${index}`, `render-image-${index}`)}/>
                                    </div>

                                    <div className="render-details">
                                        <p className="render-name">{renderRequest?.title?.length > 25 ? `${renderRequest.title.substring(0, 25)}..` : renderRequest.title}</p>
                                        <p className="render-description">{renderRequest?.description?.length > 52 ? `${renderRequest.description.substring(0, 52)}...` : renderRequest.description}</p>
                                    </div>
                                </div>
                            })
                        }
                    </Masonry>

                    {
                        displayRenderDetails ? <RenderDetails renderDetails={activeRenderDetailsView!}
                                                              renderViewLoaded={(loaded: boolean) => setRenderDetailsViewLoaded(loaded)}
                                                              onCloseProductModal={() => hideActiveRenderView()}/> : null
                    }
                </div>

            </IonContent>
        </IonPage>
    )
}

export default trackWindowScroll(Renders);
