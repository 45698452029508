import {initializeApp} from 'firebase/app';

import {
    initializeAuth,
    connectAuthEmulator,
    GoogleAuthProvider,
    browserSessionPersistence,
    indexedDBLocalPersistence,
    browserPopupRedirectResolver,
    getAuth
} from 'firebase/auth';
import {getStorage, connectStorageEmulator} from 'firebase/storage';
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';
import {Capacitor} from "@capacitor/core";

const firebaseConfig = {
    apiKey: "AIzaSyCLgbPOvtcNXwA6mReyJyYKDTRN0InIdEA",
    authDomain: "chora-88cb0.firebaseapp.com",
    projectId: "chora-88cb0",
    storageBucket: "chora-88cb0.appspot.com",
    messagingSenderId: "717464388646",
    appId: "1:717464388646:web:3eef6c835dadc016ebd2e7",
    measurementId: "G-648N4PVBY5"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

console.log('Environment:', process.env.NODE_ENV);


// export const auth = initializeAuth(firebaseApp, {
//     persistence: browserSessionPersistence,
//     popupRedirectResolver: browserPopupRedirectResolver,
// });

export const auth = (() => {
    if (Capacitor.isNativePlatform()) {
        return initializeAuth(firebaseApp, {
            persistence: indexedDBLocalPersistence,
        });
    } else {
        return getAuth();
    }
})();

export const firebaseStorage = getStorage(firebaseApp);

export const firestore = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp)

export const googleAuthProvider = new GoogleAuthProvider();

// if(process.env.NODE_ENV === 'development') {
//     connectStorageEmulator(firebaseStorage, 'localhost', 9199)
//     connectAuthEmulator(auth, 'http://localhost:9099');
//     connectFirestoreEmulator(firestore, 'localhost', 8080);
//     connectFunctionsEmulator(functions, 'localhost', 3002);
// }
