import {Redirect, Route} from 'react-router-dom';
import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact, useIonRouter
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {ellipse, square, triangle} from 'ionicons/icons';
import Renders from './pages/home/Renders';
import Community from './pages/home/Community';
import Profile from './pages/home/Profile';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import React from "react";
import Home from "./pages/home";
import SignIn from "./pages/auth/sign-in/SignIn";
import './App.scss';
import NewRender from "./pages/new-render/NewRender";
import PrivateRoute from "./shared/auth/authGuard";
import CommunityShare from './pages/community-share';

setupIonicReact();

function App() {

    return (<IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
                <Route exact path="/sign-in">
                    <SignIn/>
                </Route>
                <PrivateRoute exact path="/new-render" component={NewRender}/>
                <PrivateRoute path="/home" component={Home}/>
                <PrivateRoute component={CommunityShare} exact path={"/community-share"}/>
                <Route exact path="/">
                    <Redirect to="/home"/>
                </Route>
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>)
}

export default App;
