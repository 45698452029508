import {
    IonAvatar,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonPage, IonSpinner,
    IonText,
    IonTitle,
    IonToolbar, useIonRouter
} from '@ionic/react';
import './Profile.scss';
import {useAuth} from "../../shared/auth/useAuth";
import ImageWithFallback from "../../components/ImageWithFallback";
import {auth} from "../../firebaseConfiguration";
import AvatarPlaceHolderImage from '../../shared/assets/avatar.svg';

interface ProfileProps {
    notifications?: any[]
}

function Profile(props: ProfileProps) {
    const {notifications} = props;
    const {currentUser, authenticated} = useAuth();
    const router = useIonRouter();

    function signOut() {
        auth.signOut().then(() => {
            router.push('/sign-in', 'forward', 'replace')
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen scrollEvents={true}>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Profile</IonTitle>
                    </IonToolbar>
                </IonHeader>

                {
                    currentUser && authenticated ? <>
                        <div className="d-flex -row -gap-1 ion-padding">
                            <IonAvatar style={{width: "60px", height: "60px"}}>
                                <ImageWithFallback fallbackSrc={AvatarPlaceHolderImage} src={currentUser?.photoURL || ''}/>
                            </IonAvatar>
                            <div className="d-flex -column -gap">
                                <h5 className="m-0">{currentUser.displayName}</h5>
                                <p className="m-0 gray">{currentUser.email}</p>
                            </div>
                        </div>

                        <IonList>
                            <IonItem>
                                <IonLabel>Render Credits Balance</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonLabel>Notifications</IonLabel>
                            </IonItem>
                            <IonItem button onClick={signOut}>
                                <IonLabel>Sign Out</IonLabel>
                            </IonItem>
                        </IonList>
                        {/*{notifications && notifications.length !== 0 &&*/}
                        {/*    <>*/}
                        {/*        <h6>Notifications:</h6>*/}
                        {/*        <IonList>*/}

                        {/*            {notifications.map((notif: any) =>*/}
                        {/*                <IonItem key={notif.id}>*/}
                        {/*                    <IonLabel>*/}
                        {/*                        <IonText>*/}
                        {/*                            <h3 className="notif-title">{notif.title}</h3>*/}
                        {/*                        </IonText>*/}
                        {/*                        <p>{notif.body}</p>*/}
                        {/*                        {notif.type === 'foreground' &&*/}
                        {/*                            <p>This data was received in foreground</p>}*/}
                        {/*                        {notif.type === 'action' && <p>This data was received on tap</p>}*/}
                        {/*                    </IonLabel>*/}
                        {/*                </IonItem>*/}
                        {/*            )}*/}
                        {/*        </IonList>*/}
                        {/*    </>*/}
                        {/*}*/}
                    </> : <IonSpinner name="crescent"></IonSpinner>
                }

            </IonContent>
        </IonPage>
    );
}

export default Profile;
