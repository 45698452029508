import ColorThief from "colorthief";

function useImageHelpers() {
    function getDominantColor(renderImage: HTMLImageElement) {
        // Set default dominant color to light gray
        let dominantColor = [211, 211, 211];
        if (renderImage.naturalWidth > 0)
            dominantColor = new ColorThief().getColor(renderImage);

        return dominantColor;
    }

    function getImageShadow(render: string, index: number, renderContainerID: string, renderImageID: string) {
        const renderContainer: HTMLDivElement = document.getElementById(renderContainerID) as HTMLDivElement;
        const renderImage: HTMLImageElement = document.getElementById(renderImageID) as HTMLImageElement;

        if(renderImage){
            // Get the dominant color in the image and update the shadow styling
            const dominantColor = getDominantColor(renderImage);
            if (dominantColor) {
                const shadowColor = `rgba(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]}, .3)`;
                renderContainer.style.cssText = `box-shadow: 0 5px 18px ${shadowColor}`;
            }
        }

    }

    return {getDominantColor, getImageShadow}
}

export default useImageHelpers;
