import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import './Community.scss';
import React, {useEffect, useState} from "react";
import {collection, doc, onSnapshot} from "firebase/firestore";
import {firestore} from "../../firebaseConfiguration";
import {ChoraUserType} from "../../shared/types/user/chora-user.type";
import {CommunityRenderType} from "../../shared/types/render/community-render";
import useImageHelpers from "../../shared/hooks/imageHelpers.hook";
import {useAuth} from "../../shared/auth/useAuth";
import useFirebaseHelpers from "../../shared/hooks/firebaseHelpers.hook";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {handleImageOnError} from "../../components/ImageWithFallback";
import Masonry from "react-masonry-css";
import RenderDetails from "../render-details";
import {ActiveViewMetadataType} from "../../shared/types/animated-transition/active-view-metadata.type";
import {ActiveRenderViewProps} from "../../shared/types/render/render-details.type";
import {RenderRequestType} from "../../shared/types/render/render-requests.type";

function Community() {
    const [communityRenders, setCommunityRenders] = React.useState<CommunityRenderType[]>([]);
    const {getImageShadow} = useImageHelpers();
    const {currentUser, authenticated} = useAuth();
    const [userData, setUserData] = useState<ChoraUserType | undefined>(undefined);
    const {getDownloadableImage} = useFirebaseHelpers();
    const [activeRenderDetailsView, setActiveRenderDetailsView] = useState<ActiveRenderViewProps<CommunityRenderType> | undefined>(undefined);
    const scrollContentRef = React.useRef<HTMLIonContentElement | null>(null);
    const [displayRenderDetails, setDisplayRenderDetails] = useState<boolean>(false);
    const [renderDetailsViewLoaded, setRenderDetailsViewLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!currentUser?.uid || !authenticated) return;

        const unsubscribeUserData = onSnapshot(
            doc(firestore, 'chora-users', `${currentUser?.uid}`),
            (doc) => {
                const data = doc.data() as ChoraUserType;
                if (data) {
                    setUserData(data);
                }
            }
        );

        const unsubscribeRenderRequests = onSnapshot(
            collection(firestore, 'community-renders'),
            (querySnapshot) => {
                const communityRenderDocs = querySnapshot.docs.map((doc) => {
                    return doc.data() as CommunityRenderType;
                });

                const retrievedImageUrls = communityRenderDocs.map(async (communityRender) => {
                    if (communityRender?.renders) {
                        const updatedRendersInRequests = communityRender.renders.map(async (render) => {
                            return (await getDownloadableImage(`user-data/${currentUser?.uid}/renders/${render}`));
                        })
                        return {
                            ...communityRender,
                            raw_renders: communityRender?.renders || [],
                            renders: await Promise.all(updatedRendersInRequests)
                        };
                    } else {
                        return communityRender;
                    }
                });
                Promise.all(retrievedImageUrls).then((formattedRenderRequests) => {
                    console.log('Formatted community renders: ', formattedRenderRequests);
                    setCommunityRenders(formattedRenderRequests);
                });
            }
        )

        return () => {
            unsubscribeUserData();
            unsubscribeRenderRequests();
        };
    }, [authenticated, currentUser?.uid]);

    function hideActiveRenderView() {
        setDisplayRenderDetails(false);
        setActiveRenderDetailsView(undefined);
        setRenderDetailsViewLoaded(false);
    }

    function openRenderDetails(event: any, render: CommunityRenderType) {
        const target = event.target;
        const eventData: ActiveViewMetadataType = {
            x: target.x,
            y: target.y,
            thumbnail: {
                height: target.offsetHeight,
                width: target.offsetWidth,
            }
        };

        console.log('Event data: ', eventData);

        setActiveRenderDetailsView({
            activeRenderViewMetadata: eventData,
            activeRender: render,
            displayRenderDetails: true
        });
        setDisplayRenderDetails(true);

        scrollContentRef.current?.scrollToTop(200);
    }

    function getThumbnailCoverImage(render: CommunityRenderType): string {
        return render?.renders && render.renders.length > 0 ? render.renders[0] : '';
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Community</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Community</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <div className="ion-padding">
                    <Masonry
                        breakpointCols={2}
                        className="renders-grid mt-1"
                        columnClassName="renders-column">

                        {
                            communityRenders && communityRenders.map((render, index) => {
                                return <div key={index}
                                            style={{"--entry-index": index} as any}
                                            className={`render ${renderDetailsViewLoaded ? '-hidden' : ''}`}>
                                    <div id={`render-container-${index}`} onClick={(event) => {
                                        openRenderDetails(event, render)
                                    }} className="render-thumbnail">
                                        <LazyLoadImage id={`render-image-${index}`}
                                                       crossOrigin={''}
                                                       src={getThumbnailCoverImage(render)}
                                                       onError={(event) => handleImageOnError(event)}
                                                       afterLoad={() => getImageShadow(getThumbnailCoverImage(render), index, `render-container-${index}`, `render-image-${index}`)}/>
                                    </div>

                                    <div className="render-details">
                                        <p className="render-name">{render.title?.length > 25 ? `${render.title.substring(0, 25)}..` : render.title}</p>
                                        <p className="render-description">{render.description?.length > 52 ? `${render.description.substring(0, 52)}...` : render.description}</p>
                                    </div>
                                </div>
                            })
                        }
                    </Masonry>

                    {
                        displayRenderDetails ? <RenderDetails isCommunity={true} renderDetails={activeRenderDetailsView!}
                                                              renderViewLoaded={(loaded: boolean) => setRenderDetailsViewLoaded(loaded)}
                                                              onCloseProductModal={() => hideActiveRenderView()}/> : null
                    }
                </div>
            </IonContent>
        </IonPage>
    );
}

export default Community;
