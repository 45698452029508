import React, {createContext, useEffect, useState} from 'react';
import {auth} from "../../firebaseConfiguration";
import {User} from "firebase/auth";

interface AuthContextProps {
    currentUser: User | null;
    authenticated: boolean;
}

const defaultContext: AuthContextProps = {
    currentUser: null,
    authenticated: false
}
export const AuthContext = createContext<AuthContextProps>(defaultContext);

interface AuthContextProviderProps {
    children: React.ReactNode;
}

export function AuthProvider(props: AuthContextProviderProps) {
    const {children} = props;
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setAuthenticated(user !== null);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <AuthContext.Provider value={{currentUser, authenticated}}>
            {children}
        </AuthContext.Provider>
    );
}
