import {firebaseStorage} from "../../firebaseConfiguration";
import {ref, getDownloadURL} from "firebase/storage";

function useFirebaseHelpers() {
    async function getDownloadableImage(imagePath: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            const imageRef = ref(firebaseStorage, imagePath);

            getDownloadURL(imageRef).then((url) => {
                resolve(url);
            }).catch((error) => {
                    console.log('Error retrieving image: ', error);
                    reject(error);
                }
            )
        });
    }

    return {getDownloadableImage}
}

export default useFirebaseHelpers;
