import {FirebaseMessaging, Notification} from "@capacitor-firebase/messaging";

export default function useNotificationsHelper() {
    const checkPermissions = async () => {
        const result = await FirebaseMessaging.checkPermissions();
        return result.receive;
    };
    const requestPermissions = async () => {
        const result = await FirebaseMessaging.requestPermissions();
        return result.receive;
    };

    const getToken = async () => {
        const result = await FirebaseMessaging.getToken();
        return result.token;
    };

    const deleteToken = async () => {
        await FirebaseMessaging.deleteToken();
    };

    const getDeliveredNotifications = async () => {
        const result = await FirebaseMessaging.getDeliveredNotifications();
        return result.notifications;
    };

    const removeDeliveredNotifications = async (notifications?: Notification[]) => {
        if (!notifications) return FirebaseMessaging.removeAllDeliveredNotifications();
        return FirebaseMessaging.removeDeliveredNotifications({notifications: notifications});
    };

    const removeAllDeliveredNotifications = async () => {
        await FirebaseMessaging.removeAllDeliveredNotifications();
    };

    const subscribeToTopic = async (topic: string) => {
        await FirebaseMessaging.subscribeToTopic({topic: topic});
    };

    const unsubscribeFromTopic = async (topic: string) => {
        await FirebaseMessaging.unsubscribeFromTopic({topic: topic});
    };

    const addTokenReceivedListener = async () => {
        return FirebaseMessaging.addListener('tokenReceived', event => {
            console.log('tokenReceived', {event});
        });
    };

    const addNotificationReceivedListener = async () => {
        return FirebaseMessaging.addListener('notificationReceived', event => {
            console.log('notificationReceived', {event});
        });
    };

    const addNotificationActionPerformedListener = async () => {
        return FirebaseMessaging.addListener('notificationActionPerformed', event => {
            console.log('notificationActionPerformed', {event});
        });
    };

    const removeAllListeners = async () => {
        await FirebaseMessaging.removeAllListeners();
    };

    return {
        checkPermissions,
        removeAllListeners,
        addNotificationActionPerformedListener,
        addNotificationReceivedListener,
        addTokenReceivedListener,
        unsubscribeFromTopic,
        subscribeToTopic,
        removeAllDeliveredNotifications,
        removeDeliveredNotifications,
        getDeliveredNotifications,
        deleteToken,
        getToken,
        requestPermissions
    };
}
