import {
    IonBackButton,
    IonButton, IonButtons,
    IonContent,
    IonHeader,
    IonImg, IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage, IonRange, IonSelect, IonSelectOption, IonTextarea,
    IonTitle, IonToast,
    IonToolbar, ToastOptions, useIonRouter
} from "@ionic/react";
import React from "react";
import {Camera, CameraResultType, Photo} from "@capacitor/camera";
import {ref, uploadString} from "firebase/storage";
import {firebaseStorage, firestore} from "../../firebaseConfiguration";
import {useAuth} from "../../shared/auth/useAuth";
import {useFormik} from "formik";
import {collection, addDoc} from "firebase/firestore";

interface NewRenderFormType {
    title: string;
    description: string;
    style: string[];
    similarity: number;
    strictness: number;
    sketch_image: string;
    renderer: string;
    artists: string[];
}

export interface ToastData {
    isOpen: boolean;
    options?: ToastOptions;
}

function NewRender() {
    const {currentUser, authenticated} = useAuth();
    const [sketchFile, setSketchFile] = React.useState<Photo | null>(null);
    const [sendingRenderRequest, setSendingRenderRequest] = React.useState<boolean>(false);
    const [toastData, setToastData] = React.useState<ToastData>({
        isOpen: false,
        options: {
            message: '',
            duration: 5000,
            color: 'primary',
        }
    });
    const styles = ["Fantasy", "Concept Art", "Sci-Fi", "Solarpunk", "3D", "Cartoon"];
    const renderers = ['sd', 'cn'];
    const artists = ['Greg Rutkowski', 'Jessica Rossier', 'Ian McQue', 'Hubert Robert'];
    const router = useIonRouter();

    const newRenderFormInitialValues: NewRenderFormType = {
        title: '',
        description: '',
        style: [styles[0]],
        similarity: 8,
        strictness: 12,
        sketch_image: '',
        renderer: renderers[0],
        artists: []
    }
    const newRenderForm = useFormik({
        initialValues: newRenderFormInitialValues,
        enableReinitialize: false,
        onSubmit: submitNewRender
    });

    /**
     * Creates a new entry in the /chora-users/{userId}/render-requests collection containing
     * the render request details
     * */
    async function submitNewRender(values: NewRenderFormType) {
        console.log('New render values: ', values);
        setSendingRenderRequest(true);
        if (authenticated && currentUser && currentUser?.uid) {
            uploadSketch().then(async (uploadedSketch) => {
                if (uploadedSketch && uploadedSketch.uploaded) {
                    const userId = currentUser.uid;
                    await addDoc(collection(firestore, `chora-users`, `${userId}/render-requests`), {
                        title: values.title,
                        description: values.description,
                        style: Array.isArray(values.style) ? values.style : [values.style],
                        similarity: values.similarity,
                        strictness: values.strictness,
                        sketch_image: uploadedSketch.sketchFile,
                        engine: values.renderer,
                        artists: values.artists
                    }).then((value) => {
                        console.log('Successfully submitted new render request: ', value);
                        setToastData({
                            ...toastData,
                            isOpen: true,
                            options: {
                                ...toastData.options,
                                message: 'Successfully submitted new render request',
                                color: 'success'
                            }
                        });
                        newRenderForm.resetForm();
                        router.push('/home/renders');
                    }).catch((error) => {
                        console.error('Error submitting new render request: ', error);
                        setSendingRenderRequest(false);
                    });
                }
            }).catch((error) => {
                console.error('Error uploading sketch: ', error);
                setSendingRenderRequest(false);
            });

        }
    }

    async function selectSketchFile() {
        const selectedImage = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl,
            promptLabelHeader: 'Select Sketch'
        });
        newRenderForm.setFieldValue('sketch_image', selectedImage.dataUrl);
        selectedImage && setSketchFile(selectedImage);
    }

    function generateUniqueId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    async function uploadSketch(): Promise<{ uploaded: boolean, sketchFile?: string }> {
        return new Promise<any>(async (resolve, reject) => {
            if (sketchFile && sketchFile?.dataUrl && currentUser && currentUser?.uid) {
                const userId = currentUser.uid;
                const fileName = generateUniqueId();
                const storageRef = ref(firebaseStorage, `user-data/${userId}/sketches/${fileName}`);
                await uploadString(storageRef, sketchFile.dataUrl, 'data_url', {
                    contentType: `image/${sketchFile.format}`,
                }).then((result) => {
                    console.log('Successfully uploaded sketch: ', result);
                    resolve({uploaded: true, sketchFile: `${fileName}`});
                }).catch((error) => {
                    resolve({uploaded: false});
                })
            } else {
                resolve({uploaded: false});
            }
        })
    }

    function removeSketchSelection() {
        setSketchFile(null)
    }

    return (<IonPage>
        <IonHeader>
            <IonToolbar color="light">
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
                </IonButtons>
                <IonTitle>New Render</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding" scrollEvents={true}>
            <form onSubmit={newRenderForm.handleSubmit}>
                <div className="d-flex -column -gap-2">

                    <IonInput label="Name" name="title" onIonInput={newRenderForm.handleChange}
                              value={newRenderForm.values.title} placeholder={"The name of your project."}>
                    </IonInput>
                    <div className="d-flex -column -gap-2">
                        <IonLabel>Sketch</IonLabel>
                        <input onChange={newRenderForm.handleChange} hidden name="sketch_image"
                               value={newRenderForm.values.sketch_image}/>
                        {!sketchFile &&
                            <IonButton type="button" onClick={() => selectSketchFile()} color="light"
                                       expand="block">Select
                                Sketch</IonButton>}

                    </div>
                    {
                        sketchFile &&
                        <div className="d-flex -justify-center -column -gap-2">
                            <IonImg style={{height: "200px", width: "auto"}} src={sketchFile.dataUrl}/>
                            <IonButton onClick={removeSketchSelection} type="button"
                                       color="light">Remove Sketch</IonButton>
                        </div>
                    }

                    <IonTextarea label="Description" name="description" onIonInput={newRenderForm.handleChange}
                                 value={newRenderForm.values.description} autoGrow={true}
                                 placeholder={"Describe your sketch as best as you can."}>

                    </IonTextarea>

                    <div className="w-100 d-flex -column">
                        <div className="d-flex -column">
                            <IonLabel>Strictness</IonLabel>
                            <small>How closely to stick to your description when generating the image. The
                                higher the value, the lower the variety in outputs.</small>
                        </div>
                        <IonRange name="strictness" onIonInput={newRenderForm.handleChange}
                                  value={newRenderForm.values.strictness} aria-label="Strictness" pin={true}
                                  pinFormatter={(value: number) => `${value}`} ticks={true} snaps={true}
                                  min={0} max={30}></IonRange>
                    </div>

                    <div className="w-100 d-flex -column">
                        <div className="d-flex -column">
                            <IonLabel>Similarity</IonLabel>
                            <small>How similar to your original sketch should the output be. The higher the
                                value, the more similar</small>
                        </div>
                        <IonRange name="similarity" onIonInput={newRenderForm.handleChange}
                                  value={newRenderForm.values.similarity} aria-label="Similarity" pin={true}
                                  pinFormatter={(value: number) => `${value}`} ticks={true} snaps={true}
                                  min={0} max={10}></IonRange>
                    </div>
                    <div className="w-100 d-flex -column">
                        <div className="w-100 d-flex -column">
                            <IonLabel>Style</IonLabel>
                        </div>
                        <IonSelect name="style" onIonChange={newRenderForm.handleChange}
                                   value={newRenderForm.values.style} 
                                   placeholder="Select all the styles you'd like to use"
                                   multiple={true}>
                            {
                                styles.map((style, index) => {
                                    return <IonSelectOption key={index} value={style}>{style}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </div>
                    <div className="w-100 d-flex -column">
                        <div className="w-100 d-flex -column">
                            <IonLabel>Inspired By</IonLabel>
                        </div>
                        <IonSelect name="artists" onIonChange={newRenderForm.handleChange}
                                   value={newRenderForm.values.artists}
                                   placeholder="Select inspired by"
                                   multiple={true}>
                            {
                                artists.map((artist, index) => {
                                    return <IonSelectOption key={index} value={artist}>{artist}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </div>
                    <div className="w-100 d-flex -column">
                        <div className="w-100 d-flex -column">
                            <IonLabel>Renderer</IonLabel>
                        </div>
                        <IonSelect name="renderer" onIonChange={newRenderForm.handleChange}
                                   value={newRenderForm.values.renderer} 
                                   placeholder="Select all the renderer to use"
                                   multiple={false}>
                            {
                                renderers.map((renderEngine, index) => {
                                    return <IonSelectOption key={index} value={renderEngine}>{renderEngine}</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </div>
                    <div className="w-100 d-flex -row -gap-2 -space-between">
                        <IonButton disabled={!newRenderForm.isValid} expand="block" size="default" type="submit"
                                   color="light">Reset</IonButton>
                        <IonButton disabled={!newRenderForm.isValid} expand="block" size="default" type="submit"
                                   color="success">Generate</IonButton>
                    </div>
                </div>
            </form>
        </IonContent>
        <>
            <IonToast isOpen={toastData.isOpen} message={toastData.options?.message}
                      duration={toastData?.options?.duration}
                      color={toastData?.options?.color}></IonToast>
        </>
    </IonPage>)
}

export default NewRender;
