import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from './useAuth';

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const PrivateRoute = ({
                          component: Component,
                          ...rest
                      }: PrivateRouteProps): JSX.Element => {
    const { authenticated } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                authenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
            }
        />
    );
};

export default PrivateRoute;

